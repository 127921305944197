//A place to put any constants and magic numbers
//that will ensure they're kept constant and immutable

function Constants() {
    var DISTANCE_CUTOFF = 200;  //Distance in kms a player needs to be within to be considered at a venue
    var AVAILABLE_PLAYERS_PER_PAGE = 20;
    var SCOREBOARD_ENTRY_CUTOFF = 10;
    var LOBBY_GAMES_PER_PAGE = 1000;
    var GUID_EMPTY = "00000000-0000-0000-0000-000000000000";

    var SPORT_DETAILS = [
        {
            Id: 1000,
            Name: "AustralianRules",
            Abbreviation: "AFL"
        },
        {
            Id: 2000,
            Name: "RugbyLeague",
            Abbreviation: "NRL"
        },
        {
            Id: 3000,
            Name: "Soccer",
            Abbreviation: "SOC"
        }
    ];

    var COMP_DETAILS = [
        {
            Id: 999990,
            Name: "Venue Game",
            Abbreviation: "VENUE"
        },
        {
            Id: 999999,
            Name: "Home Game",
            Abbreviation: "HOME"
        },
        {
            Id: 171412,
            Name: "Sports Pick",
            Abbreviation: "SPORTSPICK"
        }
    ];

    this.ControlType = {
        "Input": 0,
        "Select": 1,
        "CheckBox": 2,
        "Option": 3,
        "SelectGuid": 4,
        "TextArea": 5,
        "Label": 6,
        "ClassPicker": 7,
        "Buttons": 8,
        "LabelSecure": 9,
        "Hidden": 10,
        "Radio": 11,
        "List": 12,
        "CoCurric": 13,
        "Information": 14,
        "InputSelect": 15,
        "LabelToggle": 16,
        "UTCDate": 17,
        "Number": 18,
        "Password": 19,
        "HyperLink": 20,
        "TwoArray": 21,
        "Time" : 22,
        "Duration": 23,
        "SelectorInArray": 24,
        "LabelInArray": 25,
        "InputSelectInArray": 26,
        "Date": 27,
        "TimeInArray": 28,
        "DurationInArray": 29,
        "LabelMulti": 30,
        "CheckBoxMulti": 31,
        "LabelInfo": 32,
        "LabelDateTime": 33,
        "InputDateTime": 34,
        "GenericPickerEdit": 35,
        "CheckBoxBalance": 36,
        "InputSelector": 37,
        "HiddenInArray": 38,
        "LabelAlert": 39,
        "Qualifications": 40,
        "StaffIntentions": 41,
        "FormMemberSelector": 42,
        "CheckBoxReport": 43,
        "LabelDateTimeReport": 44,
        "PreviouslyTaught": 45,
        "LabelHover": 46,
        "InputNewSelect": 47,
        "InputNewEdit": 48,
        "InputDate": 49,
        "Matthew": 50,
        "InputEnhancedInArray": 51,
        "CheckBoxRowRefresh": 52
    };

    this.ReportMode = { "Render": 0, "Report": 1, "RenderAndReport": 2 };
    this.AdminLevel = { "TCH": 0, "STU": 30, "STF": 50, "FAC": 100, "COR": 110, "LRO": 120, "ERO": 150, "TTN": 200, "TTT": 250, "SUP": 300, "SUPG": 400 };
    this.Action = {
        "Edit": 0,
        "Insert": 1,
        "Copy": 2,
        "Delete": 3,
        "Cancel": 4, 
        "Save": 5,
        "Create": 6,
        "Toggle": 7,
        "GroupToggle": 8,
        "GridJoin": 9,
        "DeletePersist": 10,
        "LabelToggle": 11,
        "EditOverride": 12,
        "MultiToggle": 13,
        "MultiGroupToggle": 14,
        "InputChange": 15,
        "DeleteAll": 16,
        "Fetch": 17,
        "Rebuild": 18,
        "Build": 19,
        "Up": 20,
        "Down": 21,
        "EditPageRefresh": 22,
        "DeletePageRefresh": 23
    };

    this.EditMode = {
        "Edit": true,
        "ReadOnly": false
    };

    this.InputType = {
        "Mouse": 0,
        "TouchScreen": 1
    };

    this.DirectionAction = {
        "Reset": 0,
        "ShiftUp": 1,
        "ShiftDown": 2
    };

    this.PickerResponse = {
        "TeacherPickerData": 0,
        "GetTeacherStats": 1,
        "TeacherFormData": 2
    }

    this.MatrixTicks = {
        "NoAccess": 0,
        "ReadOnlyAccess": 1,
        "EditAccess": 2,
        "EditAccessFilters": 3,
        "Hidden": 4,
        "HiddenFilters": 5
    };

    this.FilterElementType = {
        "Input": 0,
        "CheckBox": 1,
        "RadioButton": 2
    };

    this.FormSource = {
        "Admin": 0,
        "Template": 1
    };

    //Define accessors for the above constants
    this.getDistanceCutoff = function () {

        var thishost = window.location.hostname;

        // if they have entered an IP Address, force the distance to 200 meters
        splitUrl = thishost.split(".");
        if (!isNaN(splitUrl[0]))
            return 0.2;

        if (thishost === 'demo.coachkings.com.au' || thishost === 'home.coachkings.com.au')
            return 50000; // Basically this disables GeoLocation lockout  

        if (thishost === 'staging.coachkings.com.au')
            return 50000; // Basically this disables GeoLocation lockout

        // if they have entered production domains, force the distance to 200 meters
        if (thishost === 'www.coachkings.com.au' || thishost === 'coachkings.com.au' || thishost === 'awseb-e-y-awsebloa-13af5ooz9x7a1-19706898.ap-southeast-2.elb.amazonaws.com')
            return 50000; // Basically this disables GeoLocation lockout

        return DISTANCE_CUTOFF;
    };
     
    this.resolve = function (path, obj = self, separator = '.') {
        var properties = Array.isArray(path) ? path : path.split(separator);
        return properties.reduce((prev, curr) => prev && prev[curr], obj);
    };

    this.capitalizeFirstLetter = function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    this.delayWithCallback = function (callback, delay) {
        var timer = setTimeout(function () {
            callback.bind(this);
            callback();
        }, delay);
    };

    this.getAvailablePlayersPerPage = function () {
        return AVAILABLE_PLAYERS_PER_PAGE;
    };

    this.getScoreboardEntryCutoff = function () {
        return SCOREBOARD_ENTRY_CUTOFF;
    }

    this.getLobbyGamesPerPage = function () {
        return LOBBY_GAMES_PER_PAGE;
    }

    this.GuidEmpty = function () {
        return GUID_EMPTY;
    }

    this.RandGuid = function createGuid() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    }  

    this.saveCookie = function (key, value, expiryInDays) {
        var expiryOffset = (expiryInDays * 24 * 60 * 60 * 1000);
        var expires = new Date();
        expires.setTime(expires.getTime() + expiryOffset);
        var expiresStr = ';expires=' + expires.toUTCString();
        document.cookie = key + '=' + value + expiresStr + '; path=/';
    }

    this.getCookie = function (key) {
        var name = key + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

}

module.exports = new Constants();
