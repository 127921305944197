//An event manager that tags can use to subscribe to events, and then be notified
//when other tags publish that event
//
//NOTE: You shouldn't need to ever use or instantiate this EventManager manually, it
//is used by the eventSystem mixin
var riot = require('riot');


//Not much functionality really needed in here, because we just use
//riot's observable interface to do all the event registration and triggering
function EventManager() {
    riot.observable(this);
}


module.exports = new EventManager();