var riot = require('riot');

var TTHeader = require('./TimeTablerCommon/Common/TTHeader.tag');
var TTFooter = require('./TimeTablerCommon/Common/TTFooter.tag');
var TTcommonPopupGeneric = require('./TimeTablerCommon/Common/TTcommonPopupGeneric.tag'); 

var SDHomePage = require('./TimeTablerCommon/Home/SDHomePage.tag'); 
//var SDAboutUs = require('./TimeTabler/AboutUs/SDAboutUs.tag'); 
var SDContactUs = require('./TimeTabler/AboutUs/SDContactUs.tag');

var Login = require('./TimeTablerCommon/Login/TTLogin.tag');
var SDSignUp = require('./TimeTablerCommon/Login/SDSignUp.tag');

var popup = require('./introTags/aboutUs/TTContactUsPopupNotify.tag');
var eventSystemMixin = require('./mixins/eventsystem.js');

riot.mount('*');
