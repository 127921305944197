var riot = require('riot');
module.exports = riot.tag2('ttcontactuspopupnotify', '<div class="Excel2007Modal"><div id="idContactUsPopupNotify" class="modal"><div class="modal-content"><div class="modal-header-Notify"><span if="{DisplayMode}" class="close" onclick="{discardAction}">&times;</span> SchoolData - Notification </div><div class="modal-body"><p><div id="idMessageBody"></div></p></div><div class="modal-footer" if="{DisplayMode}"><a href="#" onclick="{confirmOkAction}" class="CommonConfirmSavebutton">OK</a></div></div></div></div>', '', '', function(opts) {
        this.mixin('eventSystem');
        this.Entry = null;
        this.Message = "";
        this.Callback = null;
        this.DisplayMode = true;

        this.on('mount', function () {

            var xxx = this;
        }.bind(this));

        this.subscribe('TTShowContactUsPopupNotify', function (e) {
            this.idMessageBody.innerHTML = e.data.Message;
            if (typeof e.data.DisplayMode !== 'undefined')
            {
                this.DisplayMode = e.data.displaymode;
            }

            if (e.data.Callback)
                this.Callback = e.data.Callback;

            this.idContactUsPopupNotify.style.display = "";
            this.update();

            this.idContactUsPopupNotify.style.display = "block";
        });

        this.subscribe('TTHideContactUsPopupNotify', function (e) {
            this.idContactUsPopupNotify.style.display = "";
            this.update();
        });

        this.discardAction = function()
        {
            this.idContactUsPopupNotify.style.display = "";
            this.update();
        }.bind(this)

        this.confirmOkAction = function()
        {
            if (this.Callback)
                this.Callback();
            this.idContactUsPopupNotify.style.display = "";
        }.bind(this)

});



