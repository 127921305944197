var riot = require('riot');

var EventSystemMixin = {
	init: function() {
        this.eventManager = require('../services/eventManager.js');
	},

    //Subscribe to be notified of the event with the specified name.  Supplied callback is
    //bound so that the 'this' keyword can be used within the callback function to refer to the tag doing the subscribing
	subscribe: function (eventName, callback) {
        this.eventManager.on(eventName, callback.bind(this));
    },

    //Publish an event, notifying all tags subscribed to that event and passing the supplied payload
    //of data
	publish: function (eventName, data) {
        var eventData = { sourceTag: this, data: ((typeof data == "undefined") ? "" : data) };
        this.eventManager.trigger(eventName, eventData);    
    }
}

riot.mixin('eventSystem', EventSystemMixin);
